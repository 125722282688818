import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import {
  // MapboxStyleDefinition,
  MapboxStyleSwitcherControl,
} from 'mapbox-gl-style-switcher';
import 'mapbox-gl/dist/mapbox-gl.css';
import './Mapbox.css';
import 'mapbox-gl-style-switcher/styles.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxPitchToggleControl from '@watergis/mapbox-gl-pitch-toggle-control';
import '@watergis/mapbox-gl-pitch-toggle-control/css/styles.css';
import { MapboxLegendControl } from '@watergis/mapbox-gl-legend';
// import { MapboxLegendControl, LegendOptions } from '@watergis/mapbox-gl-legend';
import '@watergis/mapbox-gl-legend/css/styles.css';
import { RulerControl } from 'mapbox-gl-controls';
// import { RulerControl, InspectControl } from 'mapbox-gl-controls';
import 'mapbox-gl-controls/lib/controls.css';
import config from './config';
import MapboxPopupControl from '@watergis/mapbox-gl-popup';
import '@watergis/mapbox-gl-popup/css/styles.css';
import MapboxAreaSwitcherControl from '@watergis/mapbox-gl-area-switcher';
import '@watergis/mapbox-gl-area-switcher/css/styles.css';
import {
  MapboxExportControl,
  Size,
  PageOrientation,
  Format,
  DPI,
} from '@watergis/mapbox-gl-export';
import '@watergis/mapbox-gl-export/css/styles.css';
import Legend from './Legend';
import changeText from './changeText';
import changeCheckboxTypes from './changeCheckboxTypes';

const Map: React.FC = () => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  // const map = useRef<mapboxgl.Map | null>(null);
  const [lng] = useState(config.lng);
  const [lat] = useState(config.lat);
  const [zoom] = useState(config.zoom);
  const [API_KEY] = useState(process.env.REACT_APP_MAPTILER_API_KEY);
  const [MAPBOX_API_KEY] = useState(config.mapboxAccessToken);

  useEffect(() => {
    if (process.env.REACT_APP_MAPBOX_TOKEN == null) {
      throw new Error(
        'You have to configure env REACT_APP_API_KEY, see README',
      );
    }

    if (!mapContainer.current) return; //stops map from intializing more than once

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      accessToken: config.mapboxAccessToken,
      // style: `https://api.maptiler.com/maps/topo/style.json?key=${API_KEY}`,
      style: 'mapbox://styles/mapbox/streets-v11',
      // style: 'mapbox://styles/mapbox/satellite-v9',
      center: [lng, lat],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      maxBounds: config.maxBounds,
      zoom: zoom,
      maxPitch: config.maxPitch,
      pitch: config.pitch,
      bearing: config.bearing,
      attributionControl: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      projection: 'globe',
    });

    map.on('load', () => {
      // Add new sources and layers
      try {
        // cycle through all sources and add them to the map
        for (const sources of config.layers.vectorTiles.addSources) {
          map.addSource(sources.id, {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            type: sources.type,
            // url: sources.url,
            tiles: sources.tiles,
            maxzoom: sources.maxzoom,
            minzoom: sources.minzoom,
          });
        }

        // cycle through layers and add them to map
        for (const layer of config.layers.vectorTiles.addLayers) {
          map.addLayer({
            id: layer.id,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            type: layer.type,
            source: layer.source,
            'source-layer': layer.sourceLayer,
            metaData: layer.metaData,
            minzoom: layer.minzoom,
            filter: layer.filter,
            // maxzoom: layer.maxzoom,

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            layout: layer.layout,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            paint: layer.paint,
          });
        }
        console.log('A load event occurred.');
      } catch (error) {
        console.log('Error:', error);
      }
      // add the DEM source as a terrain layer with exaggerated height
      map.setTerrain({
        source: 'mapbox-dem',
        exaggeration: 1,
      });
      map.addControl(
        new mapboxgl.NavigationControl({
          showCompass: true,
          showZoom: true,
          visualizePitch: true,
          // showPosition: false,
        }),
        'top-right',
      );
      // Add the control to the map.

      map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: { enableHighAccuracy: true },
          trackUserLocation: true,
        }),
        'top-right',
      );

      map.addControl(
        new MapboxPitchToggleControl({
          pitch: 70,
          bearing: null,
          minpitchzoom: null,
        }),
      );

      map.addControl(new RulerControl(), 'top-right');

      map.addControl(
        new MapboxGeocoder({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          accessToken: MAPBOX_API_KEY,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mapboxgl: mapboxgl,
          marker: true,
          placeholder: 'Suche', // Placeholder text for the search bar
          trackProximity: true, // Track the proximity of the map
          collapsed: true, // Collapse the search bar after a result is selected
        }),
        'top-left',
      );

      map.addControl(new mapboxgl.FullscreenControl(), 'top-left');

      if (config.legend) {
        map.addControl(
          new MapboxLegendControl(config.legend.targets, config.legend.options),
          'bottom-left',
        );
        changeCheckboxTypes();
        changeText(); // change the text of the legend
      }

      if (config.areaSwitcher) {
        map.addControl(
          new MapboxAreaSwitcherControl(config.areaSwitcher.areas, 4),
          'bottom-right',
        );
      }

      if (config.attribution) {
        map.addControl(
          new mapboxgl.AttributionControl({
            compact: true,
            customAttribution: config.attribution,
          }),
          'bottom-right',
        );
      }

      map.addControl(
        new MapboxExportControl({
          accessToken: MAPBOX_API_KEY,
          PageSize: Size.A3,
          PageOrientation: PageOrientation.Landscape,
          Format: Format.PNG,
          DPI: DPI[300],
          Crosshair: true,
          PrintableArea: true,
        }),
        'top-right',
      );

      map.addControl(
        new MapboxStyleSwitcherControl(config.styles, 'Streets'),
        'top-right',
      );
    });
    // const legend_test = new LegendControl();
    // map.addControl(legend_test, 'bottom-right');

    // onlyRendered[0].innerHTML = 'Nur gerenderte Layer';

    map.on('styledata', () => {
      // Add new sources and layers
      try {
        // cycle through all sources and add them to the map
        for (const sources of config.layers.vectorTiles.addSources) {
          map.addSource(sources.id, {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            type: sources.type,
            // url: sources.url,
            tiles: sources.tiles,
            maxzoom: sources.maxzoom,
            minzoom: sources.minzoom,
          });
        }
        // cycle through layers and add them to map
        for (const layer of config.layers.vectorTiles.addLayers) {
          map.addLayer({
            id: layer.id,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            type: layer.type,
            source: layer.source,
            'source-layer': layer.sourceLayer,
            minzoom: layer.minzoom,
            metaData: layer.metaData,
            // maxzoom: layer.maxzoom,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            layout: layer.layout,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            paint: layer.paint,
          });
        }
        if (map.getSource('mapbox-dem')) return;
        map.addSource('mapbox-dem', {
          type: 'raster-dem',
          url: `mapbox://mapbox.mapbox-terrain-dem-v1`,
          tileSize: 512,
          maxzoom: 14,
        });
        // add the DEM source as a terrain layer with exaggerated height
        // if (map.getLayer('Höhenschichten')) return;
        // if (map.getSource('mapbox-dem'))
        //   console.log(map.getSource('mapbox-dem'));
        map.setTerrain({
          source: 'mapbox-dem',
          exaggeration: 1,
        });

        // console.log(map.getLayoutProperty('bevoelkerung', 'visibility'));
        // console.log(map.getStyle().layers);
        console.log('A styledata event occurred.');
        // console.log(map.getLayoutProperty('bevoelkerung', 'visibility'));
      } catch (error) {
        console.log('Error:', error);
      }
    });

    map.on('data', () => {
      try {
        const firstLegendEl = document.getElementById('first-legend');
        const secondLegendEl = document.getElementById('second-legend');
        const thirdLegendEl = document.getElementById('third-legend');
        const fourthLegendEl = document.getElementById('fourth-legend');
        if (map.getLayoutProperty('bevoelkerung', 'visibility') === 'visible') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          firstLegendEl.style.display = 'block';
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          secondLegendEl.style.display = 'none';
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          thirdLegendEl.style.display = 'none';
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fourthLegendEl.style.display = 'none';
          // otherLegendEl.style.display = 'none';
        } else if (
          map.getLayoutProperty('bevoelkerung_extrusion', 'visibility') ===
          'visible'
        ) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          firstLegendEl.style.display = 'block';
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          secondLegendEl.style.display = 'none';
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          thirdLegendEl.style.display = 'none';
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fourthLegendEl.style.display = 'none';
          // otherLegendEl.style.display = 'none';
        } else if (
          map.getLayoutProperty('energiebedarf', 'visibility') === 'visible'
        ) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          firstLegendEl.style.display = 'none';
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          secondLegendEl.style.display = 'block';
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          thirdLegendEl.style.display = 'none';
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fourthLegendEl.style.display = 'none';
          // otherLegendEl.style.display = 'none';
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          firstLegendEl.style.display = 'none';
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          secondLegendEl.style.display = 'none';
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          thirdLegendEl.style.display = 'none';
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fourthLegendEl.style.display = 'none';
          // allLegendEl.style.display = 'none';
        }
        if (config.popup) {
          map.addControl(new MapboxPopupControl(config.popup.target));
        }
        // console.log('A data event occurred.');
      } catch (error) {
        console.log('Error:', error);
      }
    });
    map.on('idle', () => {
      changeText();
    });

    return () => {
      map.remove();
    };
  }, [lat, lng, zoom, API_KEY, MAPBOX_API_KEY]);

  return (
    <div className="map-wrap">
      <div ref={mapContainer} className="map" />
      <Legend />
    </div>
  );
};
export default Map;
